import React from "react";
import { ClipLoader } from "react-spinners";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

import { formatCurrency } from "../../utils/formatCurrency";
import { weightFormat } from "../../utils/weightFormat";
import { percentFormat } from "../../utils/percentFormat";
import { unitFormat } from "../../utils/unitFormat";
import { useTheme } from "../../hooks/theme";
import { useLegend } from "../../hooks/legend";

export default function CustomChartArea({
  data,
  name,
  dataKey, // Pode ser uma string (única linha) ou um array (múltiplas linhas)
  grid,
  lineColor, // Pode ser uma string (única linha) ou um array (cores para múltiplas linhas)
  measure,
  yAxisWidth,
  loading,
  idLinha,
}) {
  const { darkMode } = useTheme();
  const { legendMode } = useLegend();

  const CustomTooltip = (props) => {
    const { active } = props;

    if (active) {
      const { payload } = props;

      if (!payload) {
        return null;
      }

      return (
        <div
          className="tooltip-inner"
          style={{
            background: darkMode ? "#e9ecef" : "#1e1e2f",
            maxWidth: "500px",
            textAlign: "left",
          }}
        >
          {payload.map((entry, index) => {
            let valueFormatted = entry.value;
            if (measure === "R$") valueFormatted = formatCurrency(entry.value);
            if (measure === "KG") valueFormatted = weightFormat(entry.value);
            if (measure === "%") valueFormatted = percentFormat(entry.value);
            if (measure === "UN") valueFormatted = unitFormat(entry.value);
            return (
              <p
                key={index}
                style={{ color: darkMode ? "#000" : "#fff", fontWeight: 100 }}
              >
                <strong>{`${entry.name}: `}</strong>
                {valueFormatted}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader sizeUnit="px" size={90} color="#1d8cf8" loading={loading} />
        <br />
        <br />
        <h1 className="text-info" style={{ textAlign: "center" }}>
          Carregando ...
        </h1>
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart
        data={data}
        margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
      >
        <defs>
          {(Array.isArray(dataKey) ? dataKey : [dataKey]).map((key, index) => (
            <linearGradient
              key={index}
              id={`${idLinha}-${index}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="1%"
                stopColor={
                  Array.isArray(lineColor) ? lineColor[index] : lineColor
                }
                stopOpacity={0.3}
              />
              <stop
                offset="95%"
                stopColor={
                  Array.isArray(lineColor) ? lineColor[index] : lineColor
                }
                stopOpacity={0}
              />
            </linearGradient>
          ))}
        </defs>
        <XAxis
          dataKey="periodo"
          stroke={darkMode ? "#fff" : "#000"}
          style={{ fontWeight: 100 }}
        />
        <YAxis
          tickFormatter={(tick) => {
            if (measure === "R$") return formatCurrency(tick);
            if (measure === "KG") return weightFormat(tick);
            if (measure === "%") return percentFormat(tick);
            if (measure === "UN") return unitFormat(tick);
            return tick;
          }}
          stroke={darkMode ? "#fff" : "#000"}
          style={{ fontWeight: 100 }}
          width={yAxisWidth}
        />
        <CartesianGrid
          stroke={darkMode ? "rgba(255, 214, 0, 0.1)" : "rgba(0, 0, 0, 0.1)"}
          strokeWidth={1}
        />
        <Tooltip content={(props) => CustomTooltip(props)} />
        <Legend />
        {(Array.isArray(dataKey) ? dataKey : [dataKey]).map((key, index) => (
          <Area
            key={index}
            name={`${name[index] || name}`} // Nome da linha, suporta múltiplos
            type="monotone"
            dataKey={key}
            stroke={
              Array.isArray(lineColor) ? lineColor[index] : lineColor || "#000"
            }
            strokeWidth={2}
            fillOpacity={1}
            activeDot={{ r: 8 }}
            dot
            fill={`url(#${idLinha}-${index})`}
          >
            {legendMode && (
              <LabelList
                dataKey={key}
                position="top"
                offset={10}
                formatter={(value) => {
                  if (measure === "R$") return formatCurrency(value);
                  if (measure === "KG") return weightFormat(value);
                  if (measure === "%") return percentFormat(value);
                  if (measure === "UN") return unitFormat(value);
                  return value;
                }}
                fill={darkMode ? "#fff" : "#000"}
              />
            )}
          </Area>
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}
