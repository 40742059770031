import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardTitle, CardBody, CardFooter } from "reactstrap";

import { formatCurrency } from "../../../../../utils/formatCurrency";
import { percentFormat } from "../../../../../utils/percentFormat";
import api from "../../../../../services/api";

const moment = require("moment");

export default function ChartResumoSimulacao({ filteredData }) {
  const [overallSaving, setOverallSaving] = useState(0);
  const [percentSaving, setPercentSaving] = useState(0);
  const [overallSavingAvg, setPercentSavingAvg] = useState(0);
  const [percentSavingAvgFrete, setPercentSavingAvgFrete] = useState(0);

  useEffect(() => {
    async function loadTotalizadores() {
      try {
        let minDate = moment(filteredData[0].dataEmbarque, "DD/MM/YYYY");
        let maxDate = moment(filteredData[0].dataEmbarque, "DD/MM/YYYY");

        filteredData.forEach((item) => {
          const currentDate = moment(item.dataEmbarque, "DD/MM/YYYY");
          if (currentDate.isBefore(minDate)) {
            minDate = currentDate;
          }
          if (currentDate.isAfter(maxDate)) {
            maxDate = currentDate;
          }
        });

        minDate = minDate.format("YYYY-MM-DD 00:00:00");
        maxDate = maxDate.format("YYYY-MM-DD 23:59:59");

        const responseTotalizadores = await api.get("/embarcadores/saving/81", {
          params: {
            dataInicial: minDate,
            dataFinal: maxDate,
          },
        });

        if (responseTotalizadores.status === 200) {
          // Supondo que a economia geral é dada por responseTotalizadores.data.geral.economia
          const economiaGeral = responseTotalizadores.data.geral.economia;
          const economiaFrete = responseTotalizadores.data.geral.valorFreteNFe;

          const economiaMedia =
            responseTotalizadores.data.ultimos90Dias.economia;
          const economiaFreteMedia =
            responseTotalizadores.data.ultimos90Dias.valorFreteNFe;

          // Atualiza o estado com a economia geral calculada
          setOverallSaving(economiaGeral);
          setPercentSaving(economiaFrete);
          setPercentSavingAvg(economiaMedia);
          setPercentSavingAvgFrete(economiaFreteMedia);
        }
      } catch (error) {
        console.error("Erro ao carregar totalizadores:", error);
        setOverallSaving(0);
        setPercentSaving(0);
        setPercentSavingAvg(0);
        setPercentSavingAvgFrete(0);
      }
    }
    loadTotalizadores();
  }, [filteredData]);

  return (
    <Row>
      <Col lg="3" md="6">
        <Card
          style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
          className="card-stats"
        >
          <CardBody>
            <Row>
              <Col xs="4">
                <div className="info-icon text-center icon-warning">
                  <div className="info-icon text-center icon-info">
                    <i className="tim-icons icon-money-coins" />
                  </div>
                </div>
              </Col>
              <Col xs="8">
                <div className="numbers">
                  <CardTitle tag="h4">Saving</CardTitle>
                  <CardTitle tag="h3">
                    {formatCurrency(overallSaving)}
                  </CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              <i className="tim-icons icon-calendar-60" />
              Geral
            </div>
          </CardFooter>
        </Card>
      </Col>
      <Col lg="3" md="6">
        <Card
          style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
          className="card-stats"
        >
          <CardBody>
            <Row>
              <Col xs="4">
                <div className="info-icon text-center icon-danger">
                  <div className="info-icon text-center icon-danger">
                    <i className="tim-icons icon-paper" />
                  </div>
                </div>
              </Col>
              <Col xs="8">
                <div className="numbers">
                  <CardTitle tag="h4">Frete p/ Nota Fiscal</CardTitle>
                  <CardTitle tag="h3">{percentFormat(percentSaving)}</CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              <i className="tim-icons icon-calendar-60" />
              Percentual
            </div>
          </CardFooter>
        </Card>
      </Col>
      <Col lg="3" md="6">
        <Card
          style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
          className="card-stats"
        >
          <CardBody>
            <Row>
              <Col xs="4">
                <div className="info-icon text-center icon-success">
                  <div className="info-icon text-center icon-success">
                    <i className="tim-icons icon-paper" />
                  </div>
                </div>
              </Col>
              <Col xs="8">
                <div className="numbers">
                  <CardTitle tag="h4">Saving Médio</CardTitle>
                  <CardTitle tag="h3">
                    {formatCurrency(overallSavingAvg)}
                  </CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              <i className="tim-icons icon-calendar-60" />
              Últimos 90 dias
            </div>
          </CardFooter>
        </Card>
      </Col>
      <Col lg="3" md="6">
        <Card
          style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
          className="card-stats"
        >
          <CardBody>
            <Row>
              <Col xs="4">
                <div className="info-icon text-center icon-info">
                  <div className="info-icon text-center icon-info">
                    <i className="tim-icons icon-money-coins" />
                  </div>
                </div>
              </Col>
              <Col xs="8">
                <div className="numbers">
                  <CardTitle tag="h4">Frete p/ NF Médio</CardTitle>
                  <CardTitle tag="h3">
                    {percentFormat(percentSavingAvgFrete)}
                  </CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              <i className="tim-icons icon-calendar-60" />
              Últimos 90 dias
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
}
