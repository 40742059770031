import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Input,
  Form,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
import { FaFileDownload, FaFileUpload } from "react-icons/fa";
import SweetAlert from "../../../components/SweetAlert/SweetAlert";

import CustomTable from "components/CustomTableBid";
import StatusButton from "components/StatusButton";
import ChartResumoSimulacao from "../Dashboard/componentsCotacao/ChartResumoSimulacao";
import ChartSavingPorTransp from "../Dashboard/componentsCotacao/ChartSavingPorTransp";
import ChartSavingPorUF from "../Dashboard/componentsCotacao/ChartSavingPorUF";

import api from "../../../services/api";
import ApiHubBackend from "../../../services/ApiHubBackend";
import { useCompanies } from "hooks/companies";
import FileUpload from "../../../components/Input/fileUpload";
import fileDownload from "js-file-download";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { unitFormat } from "../../../utils/unitFormat";

const urlPostImportCsv = "/embarcadores/embarques/import-csv";
const urlGetExportCsv = "/embarcadores/embarques/export-csv";
const urlGetSimulaFrete = "/frete/consulta-frete";

const parseDate = (str) => {
  return new Date(str);
};

const normalizeCotacoes = (cotacoes) => {
  if (Array.isArray(cotacoes)) {
    return cotacoes.map((cotacao) => ({
      transportadora: cotacao.transportadora,
      valor: cotacao.valor != null ? cotacao.valor : 0,
    }));
  } else if (typeof cotacoes === "object") {
    return Object.keys(cotacoes).map((key) => ({
      transportadora: key,
      valor: parseFloat(cotacoes[key]) || 0,
    }));
  }
  return [];
};

const Embarque = () => {
  const [tipoEmbarque, setTipoEmbarque] = useState(null);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { empresa } = useCompanies();
  const [statusFilter, setStatusFilter] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [selectedEmbarque, setSelectedEmbarque] = useState(null);
  const [selectedTransportadora, setSelectedTransportadora] = useState(null);
  const [observacoes, setObservacoes] = useState("");
  const [freteSpot, setFreteSpot] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const sweetAlertRef = useRef(null);

  const [dataEmbarqueInicio, setDataEmbarqueInicio] = useState(null);
  const [dataEmbarqueFim, setDataEmbarqueFim] = useState(null);
  const [embarque, setEmbarque] = useState("");
  const [cliente, setCliente] = useState("");
  const [cnpj, setCnpj] = useState("");

  const loadEmbarques = async () => {
    const response = await api.get(`/embarcadores/embarques/${empresa.value}`);
    if (response.status === 200) {
      const embarques = response.data.map((emb) => ({
        ...emb,
        pesoBruto: unitFormat(emb.pesoBruto),
        valorNF: formatCurrency(emb.valorNF),
        dataEmbarque: formatDate(emb.dataEmbarque),
        dataEmbarqueDate: parseDate(emb.dataEmbarque),
      }));
      setData(embarques);
      setFilteredData(embarques);
    }
  };

  const toggleModal = (modalName) => {
    setActiveModal(modalName);
    if (modalName === null) {
      console.log("Fechou o modal e chamou os embarques de novo");
      // loadEmbarques();
    }
  };

  const handleSimulation = (row) => {
    setSelectedEmbarque(row);
    setSelectedTransportadora(row.transportadora);
    setObservacoes(row.observacoes || "");
    setFreteSpot(row.freteSpot || "");
    toggleModal("simulation");
  };

  useEffect(() => {
    if (selectedEmbarque) {
      setObservacoes(selectedEmbarque.observacoes || "");
    }
  }, [selectedEmbarque]);

  useEffect(() => {
    if (statusFilter) {
      const filtered = data.filter(
        (item) => item.statusEmbarque === statusFilter
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [statusFilter, data]);

  const handleSaveChanges = async () => {
    try {
      const updatedEmbarque = {
        statusEmbarque: "Simulado",
        transportadora: selectedTransportadora,
        observacoes,
        freteSpot,
      };

      const response = await api.put(
        `/embarcadores/embarques/${selectedEmbarque._id}`,
        updatedEmbarque
      );

      if (response.status === 200) {
        console.log("Resposta da API:", response.data);

        // Formatar a data antes de atualizar o estado
        const formattedResponseData = {
          ...response.data,
          pesoBruto: unitFormat(response.data.pesoBruto),
          valorNF: formatCurrency(response.data.valorNF),
          dataEmbarque: formatDate(response.data.dataEmbarque),
        };

        const updatedData = data.map((item) =>
          item._id === selectedEmbarque._id
            ? { ...item, ...formattedResponseData }
            : item
        );

        console.log("Dados atualizados:", updatedData);
        setData(updatedData);
        setFilteredData(updatedData);
        setSelectedEmbarque(response.data);

        toggleModal(null);
      } else {
        console.error("Falha ao salvar as mudanças:", response.status);
        alert("Falha ao salvar as mudanças");
      }
    } catch (error) {
      console.error("Erro ao salvar alterações:", error);
      alert("Erro ao salvar alterações");
    }
  };

  const applyFilters = () => {
    let filtered = data;

    if (dataEmbarqueInicio) {
      filtered = filtered.filter(
        (item) =>
          item.dataEmbarqueDate.setHours(0, 0, 0, 0) >=
          dataEmbarqueInicio.setHours(0, 0, 0, 0)
      );
    }

    if (dataEmbarqueFim) {
      filtered = filtered.filter(
        (item) =>
          item.dataEmbarqueDate.setHours(0, 0, 0, 0) <=
          dataEmbarqueFim.setHours(0, 0, 0, 0)
      );
    }

    if (embarque) {
      filtered = filtered.filter((item) =>
        item.numEmbarque.toString().includes(embarque)
      );
    }

    if (cliente) {
      filtered = filtered.filter((item) =>
        item.cliente.toLowerCase().includes(cliente.toLowerCase())
      );
    }

    if (cnpj) {
      filtered = filtered.filter((item) => item.cnpjRemetente.includes(cnpj));
    }

    setFilteredData(filtered);
  };

  const handleSimulateFrete = async () => {
    sweetAlertRef.current.loadingAlert({ title: "Simulando Frete" });

    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 3 * 24 * 60 * 60 * 1000);

    const pendingEmbarques = data
      .filter(
        (item) =>
          item.statusEmbarque === "Pendente" &&
          new Date(item.dataEmbarqueDate) >= sevenDaysAgo
        // item.numEmbarque === 78620
      )
      .map((item) => ({
        numero: item.numEmbarque,
        data: item.dataEmbarque,
        CNPJEmitente: item.cnpjRemetente,
        CEPDestinatario: item.cepDestino,
        densidade: item.densidade,
        peso: item.pesoBruto,
        valor: item.valorNF,
      }));

    const payload = {
      credencial: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...",
      codigoModal: 1,
      quantidadeRetornos: 3,
      embarques: pendingEmbarques,
    };

    try {
      const response = await ApiHubBackend.post(urlGetSimulaFrete, payload);
      console.log(response);
      console.log("Resposta do calculo");
      console.log(response.data);

      const simulatedData = response.data;
      const updatedData = data
        .map((item) => {
          const simulationResult = simulatedData.find(
            (sim) => sim.numero === item.numEmbarque
          );

          if (simulationResult) {
            const updatedCotacoes = [];
            simulationResult.freteCalculado.forEach((frete) => {
              updatedCotacoes.push({
                transportadora: frete.transportadora,
                valor: frete.custoFrete != null ? frete.custoFrete : "0.00",
              });
            });
            return { ...item, cotacoes: updatedCotacoes };
          }
          // Ignorar o item se não houver simulação
          return null;
        })
        .filter((item) => item !== null); // Filtrar itens sem simulação

      // Salvar dados simulados no banco de dados
      console.log("Atualizando dados no banco de dados");
      console.log(updatedData);

      for (const item of updatedData) {
        await api.put(`/embarcadores/embarques/${item._id}`, item);
      }
      console.log("Finalizou atualização no banco de dados");
      console.log(updatedData);

      setData(updatedData);
      setFilteredData(updatedData);

      sweetAlertRef.current.successAlert({ title: "Bom trabalho!" });
    } catch (error) {
      console.error("Erro ao simular frete:", error);
      sweetAlertRef.current.errorAlert({ title: "Erro ao simular frete" });
    }
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "content-type": "application/octet-stream",
          "Content-Disposition": "attachment;filename=embarques.csv",
        },
      };
      const result = await api.get(
        urlGetExportCsv,
        {
          params: { idEmbarcadorRemetente: empresa.value },
        },
        config
      );
      if (result.status === 200) {
        fileDownload(result.data, "embarques.csv");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Erro ao baixar planilha!");
    }
  };

  useEffect(() => {
    async function loadEmbarques() {
      setLoading(true);
      const response = await api.get(
        `/embarcadores/embarques/${empresa.value}`
      );

      if (response.status === 200) {
        const embarques = response.data.map((emb) => ({
          ...emb,
          pesoBruto: unitFormat(emb.pesoBruto),
          valorNF: formatCurrency(emb.valorNF),
          dataEmbarque: formatDate(emb.dataEmbarque),
          dataEmbarqueDate: parseDate(emb.dataEmbarque),
        }));
        setData(embarques);
        setFilteredData(embarques);
      }
      setLoading(false);
    }
    loadEmbarques();
  }, [empresa]);

  const columns = [
    {
      dataField: "dataEmbarque",
      text: "Data Embarque",
    },
    {
      dataField: "numEmbarque",
      text: "Embarque",
    },
    {
      dataField: "statusEmbarque",
      text: "Status",
      formatter: (cell) => <StatusButton status={cell} />,
    },
    {
      dataField: "cnpjRemetente",
      text: "CNPJ",
    },
    {
      dataField: "cidadeDestino",
      text: "Cidade",
    },
    {
      dataField: "ufDestino",
      text: "UF",
    },
    {
      dataField: "transportadora",
      text: "Transportadora",
    },
    {
      dataField: "tipoFrete",
      text: "Tipo Frete",
    },
    {
      dataField: "densidade",
      text: "M3",
    },
    {
      dataField: "pesoBruto",
      text: "Kg",
    },
    {
      dataField: "valorNF",
      text: "Valor de NF",
    },
    {
      dataField: "actions",
      text: "Simulação",
      formatter: (cellContent, row) => (
        <Button
          className="btn-icon btn-round btn-success"
          onClick={() => handleSimulation(row)}
        >
          <i className="tim-icons icon-simple-add" />
        </Button>
      ),
    },
    {
      dataField: "freteSpot",
      text: "Frete Spot",
    },
    {
      dataField: "observacoes",
      text: "Observações",
    },
  ];

  const handleFilter = useCallback(() => {
    setFilterIsActive(!filterIsActive);
  }, [filterIsActive]);

  return (
    <div className="content">
      <SweetAlert ref={sweetAlertRef} />
      <Row>
        <Col className="mb-2">
          <Button
            className="btn btn-round btn-success"
            onClick={() => handleDownload()}
          >
            <FaFileDownload size={25} />
            {"  "} Baixar planilha
          </Button>
          <Button
            className="btn btn-round btn-success"
            onClick={() => toggleModal("upload")}
          >
            <FaFileUpload size={25} />
            {"  "} Enviar planilha
          </Button>
          <Button
            className="btn-icon"
            color={filterIsActive ? "success" : "danger"}
            onClick={handleFilter}
          >
            {filterIsActive ? <RiFilterFill /> : <RiFilterOffFill />}
          </Button>
        </Col>
      </Row>
      <Row>
        {filterIsActive ? (
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Filtros</CardTitle>
            </CardHeader>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label md="1">Data Embarque</Label>
                  <Col md="3">
                    <FormGroup>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "data inicial",
                          style: { color: "#FFF", textAlign: "center" },
                        }}
                        onChange={(date) =>
                          setDataEmbarqueInicio(date.toDate())
                        }
                        isValidDate={() => true}
                        timeFormat={false}
                        closeOnSelect
                        locale="pt-br"
                      />
                    </FormGroup>
                  </Col>
                  <p style={{ display: "flex", alignItems: "center" }} md="1">
                    até
                  </p>
                  <Col md="3">
                    <FormGroup>
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "data final",
                          style: { color: "#FFF", textAlign: "center" },
                        }}
                        onChange={(date) => setDataEmbarqueFim(date.toDate())}
                        isValidDate={() => true}
                        timeFormat={false}
                        closeOnSelect
                        locale="pt-br"
                      />
                    </FormGroup>
                  </Col>
                  <Label md="2">Tipo de Frete</Label>
                  <Col md="2">
                    <FormGroup>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="tipoEmbarque"
                        value={tipoEmbarque}
                        onChange={(value) => setTipoEmbarque(value)}
                        options={[
                          { value: "1", label: "CIF" },
                          { value: "2", label: "FOB" },
                        ]}
                        placeholder="Escolha um tipo"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label md="1">Embarque</Label>
                  <Col md="2">
                    <FormGroup>
                      <Input
                        type="text"
                        value={embarque}
                        onChange={(e) => setEmbarque(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Label md="1">Cliente</Label>
                  <Col md="2">
                    <FormGroup>
                      <Input
                        type="text"
                        value={cliente}
                        onChange={(e) => setCliente(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Label md="2">CNPJ</Label>
                  <Col md="3">
                    <FormGroup>
                      <Input
                        type="text"
                        value={cnpj}
                        onChange={(e) => setCnpj(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button className="btn btn-primary" onClick={applyFilters}>
                  Aplicar Filtro
                </Button>
              </Form>
            </CardBody>
          </Card>
        ) : null}
        <Card>
          <CardBody>
            <CustomTable
              data={filteredData} // Usando filteredData aqui
              columns={columns}
              fileName="Embarques"
              tableName="Embarques"
              loading={loading}
              setStatusFilter={setStatusFilter}
              handleSimulateFrete={handleSimulateFrete}
            />
          </CardBody>
        </Card>
      </Row>
      <ChartResumoSimulacao filteredData={filteredData} />{" "}
      <ChartSavingPorTransp filteredData={filteredData} />{" "}
      <ChartSavingPorUF filteredData={filteredData} />{" "}
      {/* Pass filteredData as a prop */}
      {/* Modal para envio de planilha */}
      <Modal isOpen={activeModal === "upload"} toggle={() => toggleModal(null)}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal(null)}
          >
            <i className="tim-icons icon-simple-remove text-black" />
          </button>
          <ModalHeader tag="h3">Enviar planilha</ModalHeader>
        </div>
        <ModalBody className="text-center">
          <Row>
            <Col className="text-center">
              <FileUpload
                api={api}
                url={urlPostImportCsv}
                params={{ idEmbarcadorRemetente: empresa.value }}
                paramsFormData={null}
                accept=".csv"
                filekey="file"
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Modal para simulação de frete */}
      <Modal
        isOpen={activeModal === "simulation"}
        toggle={() => toggleModal(null)}
      >
        <ModalHeader toggle={() => toggleModal(null)}>
          Simulações de Frete
        </ModalHeader>
        <ModalBody>
          {selectedEmbarque && (
            <div>
              <p>
                <strong>Embarque:</strong> {selectedEmbarque.numEmbarque}
              </p>
              <p>
                <strong>Cliente:</strong> {selectedEmbarque.cliente}
              </p>
              <p>
                <strong>Cidade:</strong> {selectedEmbarque.cidadeDestino}
              </p>
              <p>
                <strong>UF:</strong> {selectedEmbarque.ufDestino}
              </p>
              <p>
                <strong>Transp. Atual:</strong>{" "}
                {selectedEmbarque.transportadora}
              </p>
              <FormGroup>
                <Label for="transportadoraSelect">
                  Selecionar Transportadora
                </Label>
                <Select
                  id="transportadoraSelect"
                  options={normalizeCotacoes(selectedEmbarque.cotacoes).map(
                    (cotacao) => ({
                      value: cotacao.transportadora,
                      label: `${
                        cotacao.transportadora
                      } - R$ ${cotacao.valor.toFixed(2)}`,
                    })
                  )}
                  value={
                    selectedTransportadora
                      ? {
                          value: selectedTransportadora,
                          label: `${selectedTransportadora} - R$ ${normalizeCotacoes(
                            selectedEmbarque.cotacoes
                          )
                            .find(
                              (cotacao) =>
                                cotacao.transportadora ===
                                selectedTransportadora
                            )
                            ?.valor.toFixed(2)}`,
                        }
                      : null
                  }
                  onChange={(option) => setSelectedTransportadora(option.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="freteSpot">Frete Spot</Label>
                <Input
                  type="textarea"
                  id="freteSpot"
                  value={freteSpot}
                  onChange={(e) => setFreteSpot(e.target.value)}
                  style={{ color: "#000" }} // Adiciona estilo de cor para o texto
                />
              </FormGroup>
              <FormGroup>
                <Label for="observacoes">Observações</Label>
                <Input
                  type="textarea"
                  id="observacoes"
                  value={observacoes}
                  onChange={(e) => setObservacoes(e.target.value)}
                  style={{ color: "#000" }} // Adiciona estilo de cor para o texto
                />
              </FormGroup>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveChanges}>
            Salvar
          </Button>
          <Button color="secondary" onClick={() => toggleModal(null)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Embarque;
