import React, { useState, useEffect } from "react";
import classNames from "classnames";
import moment from "moment"; // Importe o moment
import {
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";

import api from "../../../../../services/api";
import ChartAreaOneLine from "../../../../../components/ChartAreaOneLine";
import { useCompanies } from "../../../../../hooks/companies"; // Importa o hook useCompanies
import { useDateFilter } from "../../../../../hooks/datefilter"; // Importe o useDateFilter

export default function ChartDevolucaoPorMes() {
  const [bigChartData, setBigChartData] = useState("totalQtde");
  const [dataQtde, setDataQtde] = useState([]);
  const [dataFrete, setDataFrete] = useState([]);
  const [loading, setLoading] = useState(false);
  const { empresa, transportadora } = useCompanies(); // Obtém as seleções de empresa e transportadora
  const { startDate, endDate } = useDateFilter(); // Obtenha as datas do filtro

  // Datas padrão do gráfico
  const defaultStartDate = moment().subtract(365, "days").startOf("month");
  const defaultEndDate = moment().endOf("month");

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);

        const params = {};

        // Verifique se startDate e endDate estão definidos
        if (startDate && endDate) {
          params.dataInicial = startDate.format("YYYY-MM-DD 00:00:00");
          params.dataFinal = endDate.format("YYYY-MM-DD 23:59:59");
        } else {
          // Use as datas padrão
          params.dataInicial = defaultStartDate.format("YYYY-MM-DD 00:00:00");
          params.dataFinal = defaultEndDate.format("YYYY-MM-DD 23:59:59");
        }

        if (empresa) {
          params.embarcadores = [empresa.value]; // Adiciona o ID da empresa como array
        }

        if (transportadora) {
          params.transportadoras = [transportadora.value]; // Adiciona o ID da transportadora como array
        }

        const responseQtde = await api.get(
          "/dashboard/performance/devolucao-por-mes",
          { params }
        );

        const responseFrete = await api.get(
          "/dashboard/performance/devolucao-por-mes/valor",
          { params }
        );

        console.log(responseQtde);

        if (responseQtde.status === 200) {
          console.log(responseQtde.data);
          setDataQtde(responseQtde.data);
        }

        if (responseFrete.status === 200) {
          setDataFrete(responseFrete.data);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    loadCharts();
  }, [startDate, endDate, empresa, transportadora]);

  return (
    <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}>
      <CardHeader>
        <Row>
          <Col>
            {bigChartData === "totalQtde" ? (
              <>
                <h4>Devoluções por entregas</h4>
                <CardTitle>Soma das devoluções ocorridas por mês</CardTitle>
              </>
            ) : (
              <>
                <h4>Devoluções por Frete</h4>
                <CardTitle>
                  Soma do Frete das devoluções ocorridas por mês
                </CardTitle>
              </>
            )}
          </Col>
          <Col sm="6">
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              <Button
                color="info"
                id="0"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "totalQtde",
                })}
                onClick={() => setBigChartData("totalQtde")}
              >
                <input defaultChecked name="options" type="radio" />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Quantidade
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-coins" />
                </span>
              </Button>
              <Button
                color="info"
                id="1"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "totalFrete",
                })}
                onClick={() => setBigChartData("totalFrete")}
              >
                <input name="options" type="radio" />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Valor
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-delivery-fast" />
                </span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {bigChartData === "totalQtde" ? (
          <ChartAreaOneLine
            data={dataQtde}
            dataKey={["devolucao", "entrega"]} // Chaves para as duas linhas
            lineColor={["#1f8ef1", "#10e211"]} // Cores para cada linha
            name={["Devoluções", "Entregas"]} // Nomes das linhas
            grid="rgba(29, 140, 248, 0.1)"
            measure="Quantidade"
            yAxisWidth={100}
            loading={loading}
            idLinha="devolucao-entrega"
          />
        ) : (
          <ChartAreaOneLine
            data={dataFrete}
            dataKey={["devolucao", "entrega"]} // Chaves para as duas linhas
            lineColor={["#1f8ef1", "#10e211"]} // Cores para cada linha
            name={["Devoluções", "Entregas"]} // Nomes das linhas
            grid="rgba(29, 140, 248, 0.1)"
            measure="R$"
            yAxisWidth={100}
            loading={loading}
            idLinha="devolucao-valor"
          />
        )}
      </CardBody>
    </Card>
  );
}
